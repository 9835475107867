<template>
	<div class="storepage big-wrapper" :class="{'storepage--vendorstore' : isVendorStore}" id="store-listing">
		<store-breadcrumb v-if="isShowBreadcrumb" :listMenu="pageData" />
		<store-category v-if="isShowCategory" :categories="categoryList" :baseUrl="currentURL" :isHideHighlight="isHideMenuCategory" :isShowPopup="isPopupCategoryOpen" @closePopup="closePopupCategory" @openPopup="openPopupCategory" />
		<div class="storepage__container">
			<div class="removepadding">
				<div class="big-wrapper big-wrapper--padding">
					<div class="storepage__head" :class="{'show-border' : isAttribution, 'desktop-only' : isVendorStore}">
						<div class="storepage__title" :class="isShowSubCategory ? 'desktop-only' : ''">
							<h1 class="mr-10" v-if="canEditCategory" :class="isHideTitleMobile ? (isVendorStore ? 'desktop-only vendorstore-title' : 'desktop-only') : (isVendorStore ? 'vendorstore-title' : '')">
								<i v-if="isUsingTimer" class="icon-thunder storepage__icon"></i>
								{{ titleName }}
							</h1>
							<h2 class="mr-10" v-if="!canEditCategory" :class="isHideTitleMobile ? (isVendorStore ? 'desktop-only vendorstore-title' : 'desktop-only') : (isVendorStore ? 'vendorstore-title' : '')">
								<i v-if="isUsingTimer" class="icon-thunder storepage__icon"></i>
								{{ titleName }}
							</h2>
							<flash-deals-timer v-if="productPagination && productPagination.end_date !== '' && isUsingTimer" :endTime="productPagination.end_date || $dayjs().format()" class="storepage__timer"></flash-deals-timer>
							<div @click="openPopupCategory" class="dropdown-category" v-if="canEditCategory">
								<i class="icon-pencil-outline discovery-category-settings"></i>
							</div>
						</div>
					</div>
					<div class="storepage__border" v-if="isAttribution"></div>
					<store-subcategory v-if="isShowSubCategory" :categoryList="subCategoryList" :selectedCategory="selectedSubCategory" @onSelected="setSelectedSubCategory" />
					<div :class="`${positionInTitle ? 'filter-container  pos-title' : (isVendorStore ? 'filter-container is-vendorstore' : 'filter-container')} ${(filterSticky ? 'filter-sticky' : '')}`">
						<store-filter 
							:isShowFilter="showFilter" 
							:categorySlug="selectedCategory" 
							:selectedFilter="optionalFilter" 
							:isMultipleCategory="isMultipleCategory" 
							:showCategoryFilter="showCategoryFilter"
							:showCityFilter="showCityFilter"
							:showPaxFilter="showPaxFilter"
							:showBudgetFilter="showBudgetFilter"
							:showFlexiFilter="showFlexiFilter"
							:showBestOfferFilter="showBestOfferFilter"
							:isPromoted="isPromoted" 
							:isAttribution="isAttribution"
							:isVendorStore="isVendorStore"
							@setFilter="applyingFilter" 
						/>
					</div>
					<banner-info v-if="campaignBanner" class="campaign-banner" gtmEvent="campaign_banner" :content="campaignBanner" :location="isAttribution && 'store_list_attribution'" />
					<div class="storepage__listdeals" :class="{'storepage__listdeals--vendorstore' : isVendorStore}">
						<div v-show="resultLists && resultLists.length > 0" class="storepage__listdeals__grid">
							<store-preview-card 
								v-for="(card, index) in resultLists"
								:key="index" :preview-deal="card"
								:position="index + 1"
								:list="list"
								:showFacilities="true"
								section="other_products"
								:tracking="trackingCurrentScreen ? tracking : undefined"
							/>
						</div>
						<template v-if="isVendorStore">
							<div v-show="(!resultLists || resultLists.length < 1) && !isFetching" class="vendorstore__notfound">
								<div class="storepage__not-found__container">
									<img :src="getImageUrl('https://alexandra.bridestory.com/image/upload/assets/notfound2x-H1QRZzxWd.png').imgSrc" width="200" height="200" alt="Not Found" />
									<div class="storepage__not-found__info">
										<strong>{{ $t("Deal.no_result_sad") }}</strong>
										<p>
											{{ $t("Deal.no_result_store_list") }}
										</p>
									</div>
								</div>
							</div>
						</template>
						<template v-else>
							<div v-show="(!resultLists || resultLists.length < 1) && !isFetching" class="storepage__not-found">
								<img :src="getImageUrl('https://alexandra.bridestory.com/image/upload/assets/kering-ByFh2oJaN.png').imgSrc" alt="not-found" title="not-found" />
								<strong>{{ $t("Deal.no_result_sad") }}</strong>
								<p>
									{{
										$t("Deal.no_result_sad_msg", {
											category_name: selectedSubCategoryName || titleName
										})
									}}
								</p>
							</div>
						</template>
					</div>
					<div class="gridcol-1-1">
						<loading v-show="isFetching || isApplyingFilter" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
const storeFilter = () => import("@/components/store/listing/filter/index.vue");
const storeCategory = () => import("@/components/store/listing/tabCategory/index.vue");
const loading = () => import("@/components/misc/loading/index.vue");
const StoreBreadcrumb = () => import("@/components/store/breadcrumb/index.vue");
const CategoryStore = () => import("@/components/store/category/index.vue");
const FlashDealsTimer = () => import("@/components/store/listing/flashDealsTimer/index.vue");
import StorePreviewCard from "@/components/cards/storePreview/index.vue";
import ImageStyle from "@/components/decorators/imageStyle.vue";
import BannerInfo from "@/components/discovery/banner-info/index.vue";

import seo from "@/helpers/seoHelper";
import imageHelper from "@/helpers/imageHelper";
import { cleanLink } from "@/helpers/stringHelper";
import { getScrollXY, getDocHeight } from '@/helpers/scroll';
import { routerReplace } from '@/helpers/routerHelper';

export default {
	name: "StoreListing",
	props: {
		titleName: {
			type: String,
			default: ''
		},
		showFilter: {
			type: Boolean,
			default: false
		},
		isHideMenuCategory: {
			type: Boolean,
			default: false
		},
		isUsingTimer: {
			type: Boolean,
			default: false
		},
		isShowCategory: {
			type: Boolean,
			default: false
		},
		isShowSubCategory: {
			type: Boolean,
			default: false
		},
		isShowBreadcrumb: {
			type: Boolean,
			default: false
		},
		positionInTitle: {
			type: Boolean,
			default: false
		},
		canEditCategory: {
			type: Boolean,
			default: false
		},
		currentURL: {
			type: String,
			default: undefined
		},
		initFetchOnFilter: {
			type: Boolean,
			default: false
		},
		isPromoted: {
			type: Boolean,
			default: false
		},
		isHideTitleMobile: {
			type: Boolean,
			default: false
		},
		list: {
			type: String,
			default: undefined
		},
		fetchAPI: {
			default: undefined
		},
		isMultipleCategory: {
			type: Boolean,
			default: false
		},
		showCategoryFilter: {
			type: Boolean,
			default: false
		},
		showCityFilter: {
			type: Boolean,
			default: true
		},
		showPaxFilter: {
			type: Boolean,
			default: true
		},
		showBudgetFilter: {
			type: Boolean,
			default: true
		},
		showFlexiFilter: {
			type: Boolean,
			default: true
		},
		showBestOfferFilter: {
			type: Boolean,
			default: false
		},
		isAttribution: {
			type: Boolean,
			default: false
		},
		isVendorStore: {
			type: Boolean,
			default: false
		},
		disableFetchOnLoad: {
			type: Boolean,
			default: false
		},
		usePageParams: {
			type: Boolean,
			default: false
		},
		initialMetaRelPrev: {
			type: Object,
			default() {
				return {}
			}
		},
		initialMetaRelNext: {
			type: Object,
			default() {
				return {}
			}
		},
		initialMetaRobot: {
			type: Object,
			default() {
				return {
					"hid": "robots",
					"name": "robots",
					"content": "index, follow"
				}
			}
		},
		campaignBanner: {
			type: Object,
			required: false,
			default: null
		},
		trackingCurrentScreen: {
			type: String,
			required: false
		},
		trackingSection: {
			type: String,
			required: false
		},
		limit: {
			type: Number,
			required: false,
			default: 12
		},
		ads: {
			type: Boolean,
			required: false
		},
		filterSticky: {
			type: Boolean,
			default: false
		}
	},
	components: {
		storeFilter,
		"store-subcategory": storeCategory,
		loading: loading,
		"store-breadcrumb": StoreBreadcrumb,
		"store-category": CategoryStore,
		"flash-deals-timer": FlashDealsTimer,
		"image-style": ImageStyle,
		"store-preview-card": StorePreviewCard,
		"banner-info": BannerInfo,
	},
	mixins: [seo, imageHelper],
	computed: {
		categoryList() {
			return this.$store.getters.getCategories
		},
		subCategoryList() {
			return this.$store.getters.getSubCategories
		},
		selectedCategory() {
			return this.$store.getters.getSelectedCategory && this.$store.getters.getSelectedCategory.slug
		},
		selectedSubCategory() {
			return this.$store.getters.getSelectedSubCategory && this.$store.getters.getSelectedSubCategory.slug
		},
		pageState() {
			return this.$store.getters.getPageState
		},
		hideHighlightCategory() {
			return this.$store.getters.getSelectedCategory ? true : false
		},
		pageData() {
			return this.$store.getters.getPageState.breadcrumbList
		},
		isCategoryPopupActive() {
			return this.$store.getters.getIsCategoryActive
		},
		accountId() {
			let account = this.$store.state.account.account

			if (account) return account.accountId
			else return null
		},
		accountType(){
			let account = this.$store.state.account.account

			if (account) return account.type
			else return null
		},
		selectedSubCategoryName() {
			return this.$store.getters.getSelectedSubCategoryName;
		},
		resultLists() {
			let result = this.$store.state.storeHome.storeProducts;
			// if(result.length && this.filter.page == 1) this.filter.page = 2;
			return result;
		},
		productPagination () {
			let pagination = this.$store.state.storeHome.storePagination;
			return pagination;
		}
	},
	watch: {
		pageState() {
			// this.initFetch()
		},
		isCategoryPopupActive() {
			if (this.isCategoryPopupActive) {
				this.openPopupCategory()
			}
		},
	},
	data() {
		return {
			isFetching: false,
			isApplyingFilter: false,
			isBeginningFetch: false,
			testTitle: "",
			filter: {
				page: (this.$route.query['page'] && parseInt(this.$route.query['page'])) || 1,
				status: "all",
				limit: this.limit || 12
			},
			optionalFilter: {},
			isPopupCategoryOpen: false,
			tempPage: 0,
			widthImg: 340,
			heightImg: 148,
			metaRelPrev: this.initialMetaRelPrev ? this.initialMetaRelPrev : {},
			metaRelNext: this.initialMetaRelNext ? this.initialMetaRelNext : {},
			metaRobot: this.initialMetaRobot,
			tracking: {
				currentScreen: this.trackingCurrentScreen,
				section: this.trackingSection || undefined,
				entityType: 'product_impression',
				type: 'ctr',
				page: (this.$route.query['page'] && parseInt(this.$route.query['page'])) || 1
			}
		}
	},
	head() {
		return {
			meta: [
				this.metaRobot
			],
			link: [
				this.metaRelPrev,
				this.metaRelNext
			],
		}
	},
	methods: {
		fetchMore: function() {
			if (!this.isApplyingFilter) {
				const height = this.isAttribution ? 800 : (window.innerWidth < 768 ? 1500 : 1000);
				if (getDocHeight() < getScrollXY()[1] + window.innerHeight + height && !this.isFetching) {
					this.getAllData()
					if (!this.productPagination || this.productPagination.page < this.productPagination.total_page) {
						this.updatePageParams()
					}
				}
			}
		},
		updatePageParams() {
			if (this.usePageParams) {
				const prevPage = this.filter.page - 1;
				const nextPage = this.filter.page + 1;

				this.metaRelPrev = (this.filter.page > 1) ? {
					"hid": 'prev',
					"rel": 'prev',
					"href": process.env.BASE_URL + cleanLink(this.$route.path) + (prevPage == 1 ? '' : '?page=' + prevPage)
				} : {}

				this.metaRelNext = (nextPage <= this.productPagination.total_page) ? {
					"hid": 'next',
					"rel": 'next',
					"href": process.env.BASE_URL + cleanLink(this.$route.path) + '?page=' + nextPage
				} : {}

				this.metaRobot = (this.filter.page > 1) ? {
					"hid": "robots",
					"name": "robots",
					"content": "noindex, follow"
				} : {
					"hid": "robots",
					"name": "robots",
					"content": "index, follow"
				}
				
				let dataQuery = Object.assign({}, this.$route.query)

				if ((dataQuery["page"] && this.filter.page == 1) || this.filter.page == 1) {
					delete dataQuery["page"];
				} else {
					dataQuery["page"] = this.filter.page;
				}

				routerReplace(this, dataQuery);
			}
		},
		getAllData(initial=false) {
			if (!initial) {
				this.filter.page++;
			}

			if (!this.productPagination || this.productPagination.page < this.productPagination.total_page) {
				this.isFetching = true
				let tempFilterData = this.setOptFilter(this.optionalFilter)
				let mergeFilter = { ...this.filter, ...tempFilterData }

				if (this.ads) {
					mergeFilter.ads = true;
					mergeFilter.is_mobile = this.$device.isMobile ? true : false;
				}

				this.fetchAPI(mergeFilter).then((res) => {
					let _lists = [...this.resultLists, ...res.data.deals];
					this.tracking.page = this.filter.page;
					
					// this.filter.page++;
					// this.tempPage++;
					
					if(res.data.deals.length > 0){
						this.pushDataLayer(res.data.deals);
					}
					this.isFetching = false
					this.isApplyingFilter = false

					this.$store.dispatch('setStoreProducts', _lists);
					this.$store.dispatch('setStorePagination', res.data.pagination);

					if (this.trackingCurrentScreen) {
						this.$emit('observeList');
						this.$emit('observeAdsImpression');
						setTimeout(() => {
							this.$emit('postTracking')
						}, 500);
					}
				})
			}
		},
		browserWidth: function() {
			this.sliderHeight = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
			if (this.sliderHeight > 768) {
				return 200
			} else {
				return null
			}
		},
		applyingFilter: function(filterData) {
			this.optionalFilter = filterData;
			this.updateURLFilter(this.optionalFilter);
			setTimeout(()=>{
				if (this.initFetchOnFilter) {
					this.initFetch('apply_filter');
				}
			}, 500);
		},
		updateURLFilter(fillData) {
			let dataQuery = Object.assign({}, this.$route.query)

			if (fillData.selectedCity) dataQuery["fcity_id"] = fillData.selectedCity
			else delete dataQuery["fcity_id"]

			if (fillData.selectedSort) dataQuery["fsort"] = fillData.selectedSort
			else delete dataQuery["fsort"]

			if (fillData.selectedPax && fillData.selectedPax.length) {
				dataQuery["fmin_pax"] = parseInt(fillData.selectedPax[0])
				dataQuery["fmax_pax"] = parseInt(fillData.selectedPax[1])
			} else {
				delete dataQuery["fmin_pax"]
				delete dataQuery["fmax_pax"]
			}

			if (fillData.selectedBudget && fillData.selectedBudget.length) {
				dataQuery["fmin_price"] = parseInt(fillData.selectedBudget[0])
				dataQuery["fmax_price"] = parseInt(fillData.selectedBudget[1])
			} else {
				delete dataQuery["fmax_price"]
				delete dataQuery["fmin_price"]
			}

			if(fillData.selectedCategories.length){
				dataQuery['fcategories'] = fillData.selectedCategories.filter(cat => cat.slug != 'allsub').map(cat => cat.slug).join(',');
			} else {
				delete dataQuery.fcategories
			}

			if (fillData.selectedFlexi) dataQuery["fis_flexi"] = true; 
			else delete dataQuery["fis_flexi"]

			if (fillData.selectedBestOffer) dataQuery["best-offer"] = true; 
			else delete dataQuery["best-offer"]

			routerReplace(this, dataQuery);
		},
		setOptFilter: function(fillData) {
			let params = {}

			if (fillData.selectedCity) params["city_id"] = fillData.selectedCity

			if (fillData.selectedPax && fillData.selectedPax.length) {
				params["min_pax"] = parseInt(fillData.selectedPax[0])
				params["max_pax"] = parseInt(fillData.selectedPax[1])
			}

			if (fillData.selectedBudget && fillData.selectedBudget.length) {
				params["min_price"] = parseInt(fillData.selectedBudget[0])
				params["max_price"] = parseInt(fillData.selectedBudget[1])
			}

			if (fillData.selectedFlexi) {
				params["is_flexi"] = 1;
			}

			if (fillData.selectedBestOffer) {
				params["status"] = 'promoted';
			}

			params["sort"] = fillData.selectedSort || "latest"

			if (this.selectedSubCategory) params["category_id"] = this.selectedSubCategory
			if (this.selectedCategory) params["parent_category_id"] = this.selectedCategory

			return params
		},
		setSelectedSubCategory(categSlug) {
			if (!this.isFetching) {
				this.$store.dispatch("setSelectedSubCategory", categSlug)
			}
		},
		initFetch(type) {
			this.tempPage = 0;
			this.filter.page = 1;
			
			this.isFetching = true;
			if (type === 'apply_filter') {
				this.updatePageParams()
				this.$store.dispatch('setStoreProducts', []);
			}
			this.$store.dispatch('setStorePagination', null);
			this.getAllData(true)
		},
		closePopupCategory() {
			this.isPopupCategoryOpen = false;
			if (this.isCategoryPopupActive) this.$store.dispatch("toggleCategoryPopup")
		},
		openPopupCategory() {
			this.isPopupCategoryOpen = true;
		},
		initFilter() {
			let dataQuery = Object.assign({}, this.$route.query),
				filterOpt = {},
				arrSelectedPax = [],
				arrSelectedBudget = []
			if (dataQuery["fcity_id"]) filterOpt["selectedCity"] = dataQuery["fcity_id"]
			if (dataQuery["fsort"]) filterOpt["selectedSort"] = dataQuery["fsort"]
			if (dataQuery["fis_flexi"]) filterOpt["selectedFlexi"] = dataQuery["fis_flexi"] || false;
			if (dataQuery["best-offer"]) filterOpt["selectedBestOffer"] = dataQuery["best-offer"] || false;

			if (dataQuery["fmin_pax"]) arrSelectedPax.push(dataQuery["fmin_pax"])
			if (dataQuery["fmax_pax"]) arrSelectedPax.push(dataQuery["fmax_pax"])
			filterOpt["selectedPax"] = arrSelectedPax

			if (dataQuery["fmin_price"]) arrSelectedBudget.push(dataQuery["fmin_price"])
			if (dataQuery["fmax_price"]) arrSelectedBudget.push(dataQuery["fmax_price"])

			filterOpt["selectedBudget"] = arrSelectedBudget

			this.optionalFilter = filterOpt
		},
		pushDataLayer: function(deals) {
			let self = this
			setTimeout(function(){
				let final_list = self.list
				let list_name = self.list == 'products_you_may_like' ? 'product_list_' : 'deal_list_'
				if(self.$route.params.category){
					list_name = list_name + self.$route.params.category
					final_list = list_name
				}
				let _products = []

				for(let i = 0; i < deals.length; i++ ){
					_products[i]={
						id: deals[i].id.toString(),
						name: deals[i].title || '',
						category: deals[i].category.name,
						price: deals[i].is_discount ? deals[i].discounted_price : deals[i].normal_price || 0,
						list: final_list,
						position: ((self.filter.page-2)*6) + i + 1,
						dimension1: deals[i].vendor.id,
						dimension2: deals[i].vendor.name,
						dimension3: self.accountType || 'non_login',
						dimension4: self.accountId
					}
				}

				self.ecommercePush('PRODUCT_IMPRESSIONS', _products)
			},3000)
		}
	},
	mounted() {
		this.widthBrowser = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
		this.sliderHeight = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
		window.addEventListener("scroll", this.fetchMore)
		window.addEventListener("resize", this.browserWidth)
		this.initFilter();
		if(!this.disableFetchOnLoad) {
			this.initFetch();
		}
	},
	beforeDestroy: function() {
		window.removeEventListener("scroll", this.fetchMore)
		window.removeEventListener("resize", this.browserWidth);
	}
}
</script>

<style lang="scss" src="./style.scss" />