export const EEC_LIST = {
	store_overview_flash: 'store_overview_flash',
	store_overview_package: 'store_overview_package',
	store_overview_deal: 'store_overview_deal',
	products_you_may_like: 'products_you_may_like',
	deals_you_may_like: 'deals_you_may_like',
	deal_list_: 'deal_list_',
	product_list_: 'product_list_',
	attribution_list_: 'attribution_list_',
	flash_list: 'flash_list'
};