<template>
	<a @click="onClickLink({ id: trackingData.id, type: trackingData.type })" :href="pdpLink" :target="target ? target : '_self'">
		<div 
			class="card"
			:data-tracking-id="trackingData.id" 
			:data-entity-type="trackingData.type" 
			:data-tracking-section="trackingData.section"
			:data-tracking-page="trackingData.page"
			:data-adsid="adsId"
			:data-ads-page="trackingData.page"
			:data-ads-section="trackingData.section"
		>
			<div class="card__thumbnail">
				<div class="discount-flag" v-if="previewDeal.is_discount">
					<span class="discount-flag__text">{{ $t("Deal.save_money") }}</span>
					<span class="discount-flag__value">{{previewDeal.discount_value}}</span>
				</div>
				<div class="bestprice-flag" v-if="showBestDealBadge && !showFlashBadge">
					<i class="bestprice-flag__icon icon-star-circle"></i>
					<span class="bestprice-flag__text">{{ $t("Deal.best_deals")}}</span>
				</div>
				<div class="flashsale-flag" v-if="showFlashBadge">
					<i class="flashsale-flag__icon icon-thunder"></i>
					<span class="flashsale-flag__text">Flash Sale</span>
				</div>
				
				<div class="flexi-flag" v-if="previewDeal.is_flexi && !showFlashBadge">
					<img :src="getImageUrl('https://alexandra.bridestory.com/image/upload/assets/badge_desktop-1-HkCI5OT_8.png', 86, 22).imgSrc" :width="86" :height="22" alt="Flexible Reschedule Badge" title="Flexible Reschedule Badge">
				</div>

				<img
					class="card__image"
					:src="getImageUrl(previewDeal.image, widthImg).imgSrc"
					:alt="previewDeal.title"
					:width="widthImg"
					:height="heightImg"
					@error="setDefaultImage($event)"
				/>

				<template v-if="isEmptyStock">
					<div class="empty-bg"></div>
					<div class="empty-badge">Produk Habis</div>
				</template>

				<div class="card__location">
					<div class="card__location-content">
						<span class="icon-location-filled"></span>
						{{ previewDeal.city.name }}, {{ previewDeal.city.country_iso }}
					</div>
				</div>

				<img
					v-if="previewDeal.advertisement_id"
					:src="getImageUrl('https://alexandra.bridestory.com/image/upload/assets/ppc-badge-x-MXo5GOs.png').imgSrc"
					alt="Ppc Badge"
					title="Ppc"
					class="ppc-badge"
				/>

			</div>
			<div class="card__content">
				<div class="card__header">
					<h3 class="card__text card__text--title">
						{{ previewDeal.title }}
					</h3>
					<h4 class="card__text card__text--subtitle">
						by
						<span class="card__text--bold">{{ previewDeal.vendor.name }}</span>
						— {{ previewDeal.category.name }}
					</h4>
				</div>
				<div class="card__detail">
					<div v-if="priceType == 'hidden'" class="card__price">
						<p class="card__text card__text--ask-price">
							<span class="card__text--icon icon-price-tag-rupiah"></span>
							{{ $t("store.ask_for_price") }}
						</p>
					</div>
					<div v-else class="card__price">
						<p class="card__text card__text--normal-price" v-if="priceType == 'start_from'">{{ $t("Deal.start_from") }}</p>
						<p class="card__text card__text--normal-price strike" v-else-if="priceType == 'normal' && previewDeal.is_discount">IDR {{ normalPrice }}</p>
						<p class="card__text card__text--discounted-price">IDR {{ discountedPrice }}</p>
					</div>
					<!-- <div class="card__review" v-show="showRating">
						<div class="card__stars">
							<span v-for="n in 5" :key="n">
								<i v-if="n <= avg_review" class="icon-star-filled"></i>
								<i v-if="n > avg_review && n == Math.ceil(avg_review)" class="icon-star-half"></i>
								<i v-if="n > avg_review && n != Math.ceil(avg_review)" class="icon-star-outline"></i>
							</span>
						</div>
						<p class="card__text card__text--rating">180</p>
					</div> -->
				</div>
				<div class="card__footer">
					<div class="card__footer__facilities">
						<span class="facility" v-if="previewDeal.capacity">
							{{ previewDeal.capacity.toLocaleString() }} pax
						</span>
						<span v-for="(facility,index) in highlightedFacilities" :key="index" class="facility">
							{{ facility }}
						</span>
					</div>
					<span v-if="(previewDeal.facilities.length-2) > 0" class="facility facility--more">
						{{ `+${previewDeal.facilities.length-2}` }}
					</span>
				</div>
			</div>
		</div>
	</a>
</template>

<script>
import webViewAPI from '@/api/webView';
import AdsAPI from '@/api/ads';

import seo from "@/helpers/seoHelper";
import imageHelper from "@/helpers/imageHelper";
import { postTracking } from '@/helpers/trackingHelper';

import Cookie from "js-cookie";

export default {
	name: "StorePreviewCard",
	mixins: [seo, imageHelper],
	props: {
		previewDeal: {
			type: Object,
			required: true
		},
		position: null,
		list: '',
		showFacilities: {
			type: Boolean,
			required: false,
			default: false
		},
		target: {
			type: String,
			required: false,
			default: '_self'
		},
		showRating: {
			type: Boolean
		},
		showLocation: {
			type: Boolean
		},
		source: {
			type: String,
			required: false
		},
		page: {
			type: String,
			required: false
		},
		viewSection: {
			type: String,
			required: false
		},
		section: {
			type: String,
			required: false
		},
		imageSize: {
			type: Object,
			required: false
		},
		tracking: {
			type: Object,
			required: false
		}
	},
	data() {
		return {
			showFlashBadge: this.previewDeal.type == 'flash' && this.$route.name.includes('home-search'),
			showBestDealBadge: this.previewDeal.best_deal_guarantee && !this.previewDeal.is_flexi,
			trackingData: {
				id: this.tracking && this.previewDeal.id ? this.previewDeal.id : undefined,
				type: this.tracking && this.tracking.entityType ? this.tracking.entityType : undefined,
				section: this.tracking && this.tracking.section ? this.tracking.section : undefined,
				page: this.tracking && this.tracking.page ? this.tracking.page : undefined
			}
		}
	},
	computed: {
		widthImg() { 
			return this.imageSize && this.imageSize.width || 600
		},
		heightImg() {
			this.imageSize && this.imageSize.height || 300
		},
		normalPrice() {
			return parseInt(this.previewDeal.normal_price).toLocaleString()
		},
		discountedPrice() {
			return parseInt(this.previewDeal.discounted_price).toLocaleString()
		},
		accountId() {
			let account = this.$store.state.account.account

			if (account) return account.accountId
			else return null
		},
		accountType(){
			let account = this.$store.state.account.account

			if (account) return account.type
			else return null
		},
		highlightedFacilities() {
			if(this.previewDeal.facilities.length > 2) {
				return this.previewDeal.facilities.filter((facility, index) => index < 2)
			}else{
				return this.previewDeal.facilities
			}
		},
		remainingFacilities(){
			let numberOfFacilities = 2;
			if(this.previewDeal.capacity) {
				numberOfFacilities--;
			}
			if(this.previewDeal.min_purchase) {
				numberOfFacilities--;
			}
			let amount = 0
			if(this.previewDeal.facilities.length > 1){
				amount = this.previewDeal.facilities.length - numberOfFacilities
			}
			return amount
		},
		pdpLink(){
			let path = this.localeLink(`store/${this.previewDeal.slug}`).slice(1)
			return process.env.BASE_URL + path
		},
		isEmptyStock() {
			if(this.previewDeal.stock_status && this.previewDeal.stock_status == 'limited' && this.previewDeal.stock == 0) {
				return true
			}
			return false
		},
		priceType() {
			if(this.previewDeal.price_type) {
				return this.previewDeal.price_type
			}
			return 'normal'
		},
		trackingMeta() {
			if (this.tracking && this.tracking.type == 'search') {
				return {
					search_id: this.$nuxt.$route.query.sid || undefined,
					timestamp: this.$nuxt.$route.query.t || undefined,
					keyword: this.$nuxt.$route.query.q,
					current_screen: this.tracking.currentScreen,
					action: 'click',
					version: 2
				}
			} else if (this.tracking && this.tracking.type == 'ctr') {
				return {
					current_screen: this.tracking.currentScreen,
					section: this.tracking.section
				}
			}
		},
		adsId() {
			return this.previewDeal && this.previewDeal.advertisement_id ? this.previewDeal.advertisement_id : undefined;
		}
	},
	methods: {
		pushDataLayer(){
			let self = this
			let product = self.previewDeal

			let product_detail = {
				id: product.id,
				name: product && product.title.replace(/[^\w\s]/gi, '').replace(/\s+/g,' ') || undefined,
				category: product.category.name,
				price: product && (product.is_discount ? product.discounted_price : product.normal_price) || undefined,
				list: self.list,
				position: self.position,
				dimension1: product.vendor.id,
				dimension2: product.vendor.name,
				dimension3: self.accountType || 'non_login',
				dimension4: self.accountId,
				currency: product.currency.display
			}

			setTimeout(function(){
				self.ecommercePush('PRODUCT_CLICKS', product_detail)
			}, 3000)
		},
		logEvent(action, label) {
			const webViewApi = new webViewAPI();
			let data = {
				category: process.env.FAIR_TRACKING_CATEGORY,
				action: action,
				label: label
			}
			
			if (webViewApi.isWebView) {
				let _data = JSON.stringify(data);
				webViewApi.setLogEvent('staticCampaign', _data)
			}else{
				window.dataLayer = window.dataLayer || []
				window.dataLayer.push({
					event: 'staticCampaign',
					staticCampaign: data
				})
			}
		},
		sendTracking({ id, type }) {
			postTracking({
				context: this,
				type: this.tracking.type,
				bulk: false,
				trackingData: {
					id,
					type: this.tracking.type == 'ctr' ? 'product_conversion' : type
				},
				trackingMeta: this.trackingMeta
			});
		},
		sendConversion() {
			const account = this.$store.state.account.account;
			let actorId = account ? account.account.data.uuid : Cookie.get('BSUUID');
			let meta = {
				screen: this.tracking && this.tracking.currentScreen,
				page: this.trackingData && this.trackingData.page || 1,
				version: 2
			}

			if (this.tracking && this.tracking.section) {
				meta = {...meta, ...{ section: this.tracking.section }}
			}

			let data = {
				tracking_id: this.previewDeal.advertisement_id,
				actor_id: actorId,
				client_id: Cookie.get('BSUUID'),
				conversion_meta: meta
			}

			let adsApi = new AdsAPI(this.$axios);
			return adsApi.sendConversion(data).catch((err)=>{
				console.error(err)
			})
		},
		onClickLink(params) {
			this.pushDataLayer();

			if (this.tracking) {
				this.sendTracking(params);
			}

			if (this.adsId) {
				this.sendConversion();
			}

			if(this.source && this.source == 'fair') {
				let eventType = 'owf'
				if(window && window.location.hostname.includes('weddingweek')) {
					eventType = 'wws'
				}else if(window && window.location.hostname.includes('onlinefair')) {
					eventType = 'owf'
				}
				if(this.section) {
					this.logEvent(`product_click_${this.section}`,this.previewDeal.slug);
				}

				let previousPage = ''
				if(this.viewSection) {
					previousPage = `event_${eventType}_${this.page}_${this.viewSection}`
				}else {
					previousPage = `event_${eventType}_${this.page}`
				}

				Cookie.set('BS.previous-page', previousPage, {domain : '.bridestory.com', path : '/'});
			}

			if(this.$route.path == '/store' || this.$route.path == '/id/store') {
				Cookie.set('BS.previous-page', 'store_home_' + this.section, {domain : '.bridestory.com', path : '/'});
			}

			if(this.$route.name.indexOf('store-slug') > -1) {
				let _name = null;
				if(this.section == 'deal_by_product') {
					_name = 'store_pdp_' + this.$route.params.slug + '_related_product_by_product';
				} else if(this.section == 'deal_by_vendor') {
					_name = 'store_pdp_' + this.$route.params.slug + '_related_product_by_vendor';
				}
	
				if(_name)Cookie.set('BS.previous-page', _name, {domain : '.bridestory.com', path : '/'});
			}
		}
	}
}
</script>

<style lang="scss" src="./style.scss" scoped />