import Cookie from "js-cookie";

export default class StoreAPI {
	constructor($axios) {
		this.baseURL = process.env.BACKEND_BASE_URL
		this.baseURLPS = process.env.BACKEND_BASE_URL_PS
		this.dealsPath = process.env.DEALS_PATH
		this.$axios = $axios;
		if (process.server) { 
			this.$axios.defaults.headers.common['Cookie'] = ''
		}
	}

	getHomeData(params) {
		return this.$axios.request({
			url: `${this.baseURL}/ms/homes/api/v1/homes`,
			method: "GET",
			params: params,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	getAttributionStoreCategories(attribution_id) {
		return this.$axios.request({
			url: `${this.baseURL}/${this.dealsPath}/attributions/${attribution_id}/categories`,
			methods: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		})
	}
	
	getStoreCategories() {
		return this.$axios.request({
			url: `${this.baseURL}/${this.dealsPath}/categories`,
			methods: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	getSelectedStoreCategories(categID) {
		return this.$axios.request({
			url: `${this.baseURL}/${this.dealsPath}/categories/${categID}`,
			methods: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	getStoreProducts(params) {
		let paramsData = params
		paramsData.price_type = 'all'
		
		return this.$axios.request({
			url: `${this.baseURL}/${this.dealsPath}/deals`,
			method: "GET",
			params: paramsData,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	getFlashDeals(params) {
		return this.$axios.request({
			url: `${this.baseURL}/${this.dealsPath}/flash-deals`,
			method: "GET",
			params: params,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	postProductToCart(data) {
		return this.$axios.request({
			url: `${this.baseURL}/${this.dealsPath}/carts`,
			method: "POST",
			data: data,
			headers: {
				"Content-Type": "application/json",
				"Authorization": Cookie.get('BSID'),
				// ...this.utmData
			}
		})
	}

	getCartSummary(params) {
		return this.$axios.request({
			url: `${this.baseURL}/${this.dealsPath}/carts`,
			method: "GET",
			params: params,
			headers: {
				"Content-Type": "application/json",
				"Authorization": Cookie.get('BSID')
			}
		})
	}

	postCheckout(data) {
		return this.$axios.request({
			url: `${this.baseURL}/${this.dealsPath}/transactions/checkout`,
			method: "POST",
			data: data,
			headers: {
				"Content-Type" : "application/json",
				"Authorization": Cookie.get('BSID'),
			}
		})
	}

	getProductDetail(id) {
		return this.$axios.request({
			method: "GET",
			url: `${this.baseURL}/${this.dealsPath}/deals/${id}`,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}

	setView(data) {
		return this.$axios.request({
			method: "POST",
			url: `${this.baseURL}/ms/view/v1/views`,
			data: data,
			headers: {
				"Content-Type": "application/json"
			}
		})
	}
}
